import { DeviceInfo } from "./device-info";

export class InstallationComplete {
    requestId: number;
    finishDateFact: Date;
    comment: String;
    devices: DeviceInfo[] = [];
    activityId: number;
    activityResultId: number;
    activityWorkflowTransitionId: number;
    merchantMemberName: string;
    merchantMemberEmail: string;
}