import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, Type} from "@angular/core";
import { AttachmentType, DeviceStatus, RequestType } from "../../../../enums";
import { Activity } from "../../../../models/activity/activity";
import { Request } from '../../../../../shared/models/request/request';
import { ActivitiesService } from "../../../../services/activities.service";
import { KeyValueObject } from "../../../../models/core/KeyValueObject";
import { CompleteActivityResultPropertiesData } from "../../flow-components/complete-activity-result-properties/complete-activity-result-properties-data";
import { Observable, Subject, forkJoin, of } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ActivitySuccessResultFlowOrder } from "./activity-success-result-flow-order";
import { For1CService } from "../../../../services/for-1c.service";
import { GetContragentInfoFrom1CResponse } from "../../../../models/for-1c/get-contragent-info/get-contragent-info-from-1c-response";
import { CompleteActivityDismantledSeriesData } from "../../flow-components/complete-activity-dismantled-series/complete-activity-dismantled-series.data";
import { DialogService } from "@progress/kendo-angular-dialog";
import { CompleteActivityResultPropertiesComponent } from "../../flow-components/complete-activity-result-properties/complete-activity-result-properties.component";
import { AppService } from "../../../../../app.service";
import { BaseFlowComponent } from "../../flow-components/base-flow-component/base-flow-component";
import { CompleteActivityDismantledSeriesComponent } from "../../flow-components/complete-activity-dismantled-series/complete-activity-dismantled-series.component";
import { DualChoiceComponent } from "../../flow-components/dual-choice/dual-choice.component";
import { CompleteActivityDismantledSimcardData } from "../../flow-components/complete-activity-dismantled-simcard/complete-activity-dismantled-simcard-data";
import { CompleteActivityDismantledSimcardComponent } from "../../flow-components/complete-activity-dismantled-simcard/complete-activity-dismantled-simcard.component";
import { LookupService } from "../../../../services/lookup.service";
import { DeviceTypeEnum } from "../../../../enums/device-type.enum";
import { RequestsService } from "../../../../services/requests.service";
import { NewRequestDevice } from "../../../../models/request/new-request/new-request-device";
import { Attachment } from "../../../../models/attachment";
import { AttachmentsService } from "../../../../services/attachments.service";
import { UploadFileData } from "../../../../models/attachment/upload-file-data";
import { CompleteActivityRequiredAttachmentData } from "../../flow-components/complete-activity-required-attachment/complete-activity-required-attachment-data";
import { CompleteActivityRequiredAttachmentComponent } from "../../flow-components/complete-activity-required-attachment/complete-activity-required-attachment.component";
import { AttachmentTypeConfigurationsService } from "../../../../services/attachment-type-configurations.service";
import { CompleteActivitySummaryData, CompleteActivitySummaryGeneralEquipment, CompleteActivitySummarySimcardEquipment } from "../../flow-components/complete-activity-summary/complete-activity-summary-data";
import { CompleteActivitySummaryComponent } from "../../flow-components/complete-activity-summary/complete-activity-summary.component";
import { CustomerEnum } from "../../../../enums/customer.enum";
import { NomenclatureService } from "../../../../services/nomenclature.service";
import { NomenclatureDto } from "../../../../models/nomenclature/nomenclatureDto";
import { InstallFlowDeviceData } from "../../flow-components/install-flow-components/install-flow-device/install-flow-device.data";
import { InstallFlowSimcardData } from "../../flow-components/install-flow-components/install-flow-simcard/install-flow-simcard-data";
import { InstallFlowDeviceComponent } from "../../flow-components/install-flow-components/install-flow-device/install-flow-device.component";
import { InstallFlowSimcardComponent } from "../../flow-components/install-flow-components/install-flow-simcard/install-flow-simcard.component";
import { DeviceInfo } from "../../../../models/device/device-info";
import { InstallFlowSummarySummaryData, SummaryDeviceEquipment, SummarySimcardEquipment } from "../../flow-components/install-flow-components/install-flow-summary/install-flow-summary-data";
import { InstallFlowSummaryComponent } from "../../flow-components/install-flow-components/install-flow-summary/install-flow-summary.component";
import { CompleteActivitySignatureComponent } from "../../flow-components/complete-activity-signature/complete-activity-signature.component";
import { InstallationComplete } from "../../../../models/device/installation-complete.model";
import { NotificationType } from "../../../../../core/services/notification-type";
import { CompleteActivityInstallSignatureData } from "../../flow-components/complete-activity-install-signature/complete-activity-install-signature.data";
import { CompleteActivityInstallSignatureComponent } from "../../flow-components/complete-activity-install-signature/complete-activity-install-signature.component";
import { CompleteActivityUninstallSignatureData } from "../../flow-components/complete-activity-uninstall-signature/complete-activity-uninstall-signature.data";
import { CompleteActivityUninstallSignatureComponent } from "../../flow-components/complete-activity-uninstall-signature/complete-activity-uninstall-signature.component";

@Component({
    selector: 'activity-success-result-flow-container',
    templateUrl: './activity-success-result-flow-container.html',
    styleUrls: ['./activity-success-result-flow-container.scss',
        '../../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
        '../../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss']
})
export class ActivitySuccessResultFlowContainer implements OnInit, OnDestroy {

    flowOrdering = [];
    openFlowComponent: () => void;

    @Input() requestTypeId: number;
    @Input() nextActivityStatusId: number;
	@Input() activity: Activity;
	@Input() request: Request;
    @Input() activityWorkflowTransitionId: number;

    @Output() continue: EventEmitter<any> = new EventEmitter<any>();

    private _destroy$: Subject<boolean> = new Subject<boolean>();

    private useImageAnalyze: boolean = true;

    // переменные ui
    dataLoading: boolean = false;
    private currentOrderIndex: number = 0;
    private isForwardMovement: boolean = true;

    // данные для других окон
    private requestUninstallDevices: NewRequestDevice[] = [];
    private erpContragentInfo: GetContragentInfoFrom1CResponse;
    private activityResults: KeyValueObject[] = [];
    private mobileNomenclatures: KeyValueObject[] = [];
    private uploadedFiles: UploadFileData[] = [];
    private attachmentTypes: KeyValueObject[] = [];

    constructor(
        private activitiesService: ActivitiesService,
        private requestsService: RequestsService,
        private attachmentsService: AttachmentsService,
        private attachmentTypeConfigurationsService: AttachmentTypeConfigurationsService,
        private for1CService: For1CService,
        private nomenclatureService: NomenclatureService,
        private lookupService: LookupService,
        private dialogService: DialogService,
        private appService: AppService) {
    }

    public ngOnDestroy(): void {
		this._destroy$.next(true);
		this._destroy$.complete();
	}

    ngOnInit(): void { 
        this.dataLoading = true;

        this.initializeFlow();

        this.activity.attachments.map(attachment => {
            this.loadFileData(attachment).then(file => {
                this.uploadedFiles.push(file);
            })
        });
        const requestUninstallDevices$ = this.requestsService.getRequestUninstallDevices(this.request.requestId);
        const erpContragentInfo$ = this.for1CService.getContragentInfo(this.request.customerContragentIntegraCode);
        const activityResults$ = this.activitiesService.getActivityResults(this.activity.activityId, this.nextActivityStatusId);
        const mobileNomenclatures$ = this.nomenclatureService.getPartnumberByName('SIM-карта');
        const requiredAttachmentTypes$ = this.attachmentTypeConfigurationsService.getRequiredAttachmentTypes(this.request.requestId, this.nextActivityStatusId);
        const attachmentTypes$ = this.lookupService.getData("attachment-types", null);

        forkJoin(
                requestUninstallDevices$, 
                erpContragentInfo$, 
                activityResults$,
                mobileNomenclatures$, 
                attachmentTypes$,
                requiredAttachmentTypes$)
            .pipe(takeUntil(this._destroy$))
            .subscribe((results) => {
                this.requestUninstallDevices = results[0].filter(x => 
                    x.deviceStatusId != DeviceStatus.installed && x.deviceStatusId != DeviceStatus.inRequest);
                this.erpContragentInfo = results[1].data;
                this.activityResults = results[2].map(x => new KeyValueObject(x.activityResultId, x.activityResult));
                
                const mobileNomenclaturesResponse = results[3] as NomenclatureDto[];
                if (mobileNomenclaturesResponse && mobileNomenclaturesResponse.length > 0) {
                    this.mobileNomenclatures = mobileNomenclaturesResponse.map(x => 
                        new KeyValueObject(x.nomenclatureId, x.nomenclatureName)
                    );
                }

                this.attachmentTypes = results[4];
                let requiredAttachmentTypeIds: number[] = [];
                if (results[5].data != null && results[5].data.data && results[5].data.data.length > 0) {
                    results[5].data.data.forEach(x => { requiredAttachmentTypeIds = requiredAttachmentTypeIds.concat(x.attachmentTypes.map(at => at.attachmentTypeId)); });
                    requiredAttachmentTypeIds = requiredAttachmentTypeIds.filter((value, index, self) => {
                        return self.indexOf(value) === index;
                    });
                }

                this.initData();

                if (requiredAttachmentTypeIds.length > 0) {
                    requiredAttachmentTypeIds.forEach(id => this.addRequiredAttachmentFlowComponent(id));
                }
                this.removeLastDefaultItem(ActivitySuccessResultFlowOrder.completeActivityRequiredAttachment);

                this.dataLoading = false;
                
                this.openFlowComponent()
            });
    }

    initializeFlow(){
        switch (this.request.requestTypeId) {
            case RequestType.install:
            case RequestType.installCRE:  
            {
                this.flowOrdering = [
                    {
                        component: CompleteActivityResultPropertiesComponent,
                        orderNumber: ActivitySuccessResultFlowOrder.completeActivityResultProperties,
                        data: null as CompleteActivityResultPropertiesData,
                        title: "Результат работы"
                    },
                    {
                        orderNumber: ActivitySuccessResultFlowOrder.completeActivityDismantledSeries,
                        data: null as InstallFlowDeviceData,
                        title: "Серийное оборудование"
                    },
                    {
                        orderNumber: ActivitySuccessResultFlowOrder.completeActivityDismantledSimcard,
                        data: null as InstallFlowSimcardData,
                        title: "Сведения о SIM-карте"
                    },
                    {
                        orderNumber: ActivitySuccessResultFlowOrder.completeActivityDismantledSimcardAddedMore,
                        data: "Добавить еще SIM-карту?",
                        hide: false
                    },
                    {
                        orderNumber: ActivitySuccessResultFlowOrder.completeActivityRequiredAttachment,
                        data: new CompleteActivityRequiredAttachmentData,
                        title: "Фото документов"
                    },
                    {
                        orderNumber: ActivitySuccessResultFlowOrder.completeActivitySummary,
                        data: new InstallFlowSummarySummaryData,
                        title: "Резюме"
                    },
                    {
                        orderNumber: ActivitySuccessResultFlowOrder.completeActivitySignature,
                        data: new CompleteActivityInstallSignatureData,
                        title: "Подпись"
                    },
                ];
                
                this.openFlowComponent = this.openInstallFlowComponent;

                break;
            }                    
            default:
            {
                this.flowOrdering = [
                    {
                        component: CompleteActivityResultPropertiesComponent,
                        orderNumber: ActivitySuccessResultFlowOrder.completeActivityResultProperties,
                        data: null as CompleteActivityResultPropertiesData,
                        title: "Результат работы"
                    },
                    {
                        orderNumber: ActivitySuccessResultFlowOrder.completeActivityDismantledSeries,
                        data: null as CompleteActivityDismantledSeriesData,
                        title: "Основное оборудование"
                    },
                    {
                        orderNumber: ActivitySuccessResultFlowOrder.completeActivityDismantledSeriesAddedMore,
                        data: "Добавить еще серийное оборудование?"
                    },
                    {
                        orderNumber: ActivitySuccessResultFlowOrder.completeActivityDismantledSimcard,
                        data: null as CompleteActivityDismantledSimcardData,
                        title: "Сведения о SIM-карте"
                    },
                    {
                        orderNumber: ActivitySuccessResultFlowOrder.completeActivityDismantledSimcardAddedMore,
                        data: "Добавить еще SIM-карту?"
                    },
                    {
                        orderNumber: ActivitySuccessResultFlowOrder.completeActivityRequiredAttachment,
                        data: new CompleteActivityRequiredAttachmentData,
                        title: "Фото документов"
                    },
                    {
                        orderNumber: ActivitySuccessResultFlowOrder.completeActivitySummary,
                        data: new CompleteActivitySummaryData,
                        title: "Резюме"
                    },
                    {
                        orderNumber: ActivitySuccessResultFlowOrder.completeActivitySignature,
                        data: new CompleteActivityUninstallSignatureData,
                        title: "Подпись"
                    }
                ];

                this.openFlowComponent = this.openDeinstallFlowComponent;

                break;
            }
        }
    }

    initData(){
        switch (this.request.requestTypeId) {
            case RequestType.install:
            case RequestType.installCRE:
                {
                    this.initFlowOrderingData(ActivitySuccessResultFlowOrder.completeActivityResultProperties, this.getCompleteActivityResultPropertiesData());
                    this.initFlowOrderingData(ActivitySuccessResultFlowOrder.completeActivityDismantledSeries, this.getInstallFlowDeviceData());
                    this.initFlowOrderingData(ActivitySuccessResultFlowOrder.completeActivityDismantledSimcard, this.getInstallFlowSimcardData());
                    break;
                }
            default:
                {
                    this.initFlowOrderingData(ActivitySuccessResultFlowOrder.completeActivityResultProperties, this.getCompleteActivityResultPropertiesData());
                    this.initFlowOrderingData(ActivitySuccessResultFlowOrder.completeActivityDismantledSeries, this.getCompleteActivityDismantledSeriesData());
                    this.initFlowOrderingData(ActivitySuccessResultFlowOrder.completeActivityDismantledSimcard, this.getCompleteActivityDismantledSimcardData());

                    if (this.requestUninstallDevices.some(x => x.deviceTypeId == DeviceTypeEnum.terminal)) {
                        const seriesDevices = this.requestUninstallDevices.filter(x => x.deviceTypeId == DeviceTypeEnum.terminal);
                        seriesDevices.reverse().forEach(seriesDevice => this.addDismantledSeriesFlowComponent(seriesDevice));
                        this.removeLastDefaultItem(ActivitySuccessResultFlowOrder.completeActivityDismantledSeries);
                    }
    
                    if (this.requestUninstallDevices.some(x => x.deviceTypeId == DeviceTypeEnum.simCard)) {
                        const simDevices = this.requestUninstallDevices.filter(x => x.deviceTypeId == DeviceTypeEnum.simCard);
                        simDevices.reverse().forEach(simDevice => this.addSimcardFlowComponent(simDevice));
                        this.removeLastDefaultItem(ActivitySuccessResultFlowOrder.completeActivityDismantledSimcard);
                    }
                    break;   
                }
        }
    }

    openDeinstallFlowComponent() {
        switch (this.currentOrderNumber) {
            case ActivitySuccessResultFlowOrder.completeActivityResultProperties:
                this.showFlowComponentDialog(CompleteActivityResultPropertiesComponent);
                break;
            
            case ActivitySuccessResultFlowOrder.completeActivityDismantledSeries:
                this.showFlowComponentDialog(CompleteActivityDismantledSeriesComponent);
                break;

            case ActivitySuccessResultFlowOrder.completeActivityDismantledSeriesAddedMore:
                let dismantledSeriesOrderingItem = {
                    orderNumber: ActivitySuccessResultFlowOrder.completeActivityDismantledSeries,
                    data: this.getCompleteActivityDismantledSeriesData(),
                    title: 'Основное оборудование'
                };
                this.showDualChoiceDialog(dismantledSeriesOrderingItem);
                break;

            case ActivitySuccessResultFlowOrder.completeActivityDismantledSimcard:
                this.showFlowComponentDialog(CompleteActivityDismantledSimcardComponent);
                break;

            case ActivitySuccessResultFlowOrder.completeActivityDismantledSimcardAddedMore:
                let dismantledSimcardOrderingItem = {
                    orderNumber: ActivitySuccessResultFlowOrder.completeActivityDismantledSimcard,
                    data: this.getCompleteActivityDismantledSimcardData(),
                    title: 'Сведения о SIM-карте'
                };
                this.showDualChoiceDialog(dismantledSimcardOrderingItem);
                break;

            case ActivitySuccessResultFlowOrder.completeActivityRequiredAttachment:
                this.showFlowComponentDialog(CompleteActivityRequiredAttachmentComponent);
                break;

            case ActivitySuccessResultFlowOrder.completeActivitySummary:
                this.initFlowOrderingData(ActivitySuccessResultFlowOrder.completeActivitySummary, this.getCompleteActivitySummaryData());
                this.showFlowComponentDialog(CompleteActivitySummaryComponent);
                break;

            case ActivitySuccessResultFlowOrder.completeActivitySignature:
                this.initFlowOrderingData(ActivitySuccessResultFlowOrder.completeActivitySignature, this.getUninstallSignatureFlowData());
                this.showFlowComponentDialog(CompleteActivityUninstallSignatureComponent);
                break;

            default:
                this.ngOnDestroy();
                this.continue.emit();
                return;
        }
    }

    openInstallFlowComponent() {
        switch (this.currentOrderNumber) {
            case ActivitySuccessResultFlowOrder.completeActivityResultProperties:
                this.showFlowComponentDialog(CompleteActivityResultPropertiesComponent);
                break;
            
            case ActivitySuccessResultFlowOrder.completeActivityDismantledSeries:
                this.showFlowComponentDialog(InstallFlowDeviceComponent);
                break;

            case ActivitySuccessResultFlowOrder.completeActivityDismantledSimcard:
                this.showFlowComponentDialog(InstallFlowSimcardComponent);
                break;

            case ActivitySuccessResultFlowOrder.completeActivityDismantledSimcardAddedMore:
                let dismantledSimcardOrderingItem = {
                    orderNumber: ActivitySuccessResultFlowOrder.completeActivityDismantledSimcard,
                    data: this.getInstallFlowSimcardData(),
                    title: 'Сведения о SIM-карте'
                };
                let simcardData = this.getInstallSimcardEquipmentData().map(x => x.iccid);
                if (simcardData.length > 0) {
                    this.hideComponent(ActivitySuccessResultFlowOrder.completeActivityDismantledSimcard, true);
                }
                this.showDualChoiceDialog(dismantledSimcardOrderingItem, simcardData, 'Обнаруженны следующие серийные номера устройств:');
                break;

            case ActivitySuccessResultFlowOrder.completeActivityRequiredAttachment:
                this.showFlowComponentDialog(CompleteActivityRequiredAttachmentComponent);
                break;

            case ActivitySuccessResultFlowOrder.completeActivitySummary:
                this.initFlowOrderingData(ActivitySuccessResultFlowOrder.completeActivitySummary, this.getInstallFlowSummaryData());
                this.showFlowComponentDialog(InstallFlowSummaryComponent);
                break;

            case ActivitySuccessResultFlowOrder.completeActivitySignature:
                this.initFlowOrderingData(ActivitySuccessResultFlowOrder.completeActivitySignature, this.getInstallSignatureFlowData());
                this.showFlowComponentDialog(CompleteActivityInstallSignatureComponent);
                break;

            default:
                this.ngOnDestroy();
                this.continue.emit();
                return;
        }
    }

    showFlowComponentDialog(componentContentType: any, continueEvent: Function = null) {
        let componentData = this.currentComponentData;
        let componentTitle = this.currentComponentTitle;

        let dialogRef = this.dialogService.open({ 
            content: componentContentType, 
            title: componentTitle,
            width: this.appService.isMobileDevice ? '100%' : '100%', 
            height: '100%' 
        });

        let component = dialogRef.content.instance as BaseFlowComponent<typeof componentData>;
        component.data = componentData;
        component.isForwardMovement = this.isForwardMovement;
        component.useImageAnalyze = this.useImageAnalyze;
        component.goToNextEvent.subscribe(() => {
            dialogRef.close();
            this.goToNext();
        });
        component.goToPreviousEvent.subscribe(() => {
            dialogRef.close();
            this.goToPrevious();
        });
        if(continueEvent){
            component.onContinueEvent.subscribe((result) => {
                continueEvent(result);
            });
        }
    }

    showDualChoiceDialog(flowOrderingItem: { orderNumber: number, data: any, title: string }, 
        resultLines: string[] = null, resultLinesTitle: string = '') 
    {
        let dialogRef = this.dialogService.open({ 
            content: DualChoiceComponent, 
            width: this.appService.isMobileDevice ? '100%' : '100%', 
            height: '100%' 
        });

        let component = dialogRef.content.instance as DualChoiceComponent;
        component.title = this.currentComponentData;
        if (resultLines != null) {
            component.resultLinesTitle = resultLinesTitle;
            component.showResultLines = true;
            component.resultLines = resultLines;
        }

        component.negativeEvent.subscribe(() => {
            dialogRef.close();
            this.isForwardMovement 
                ? this.goToNext() 
                : this.goToPrevious();
        });
        component.positiveEvent.subscribe(() => {
            this.isForwardMovement = true;
            this.flowOrdering.splice(this.currentOrderIndex, 0, flowOrderingItem);
            dialogRef.close();
            this.openFlowComponent();
        });
    }

    get currentComponentData(): any {
        return this.flowOrdering[this.currentOrderIndex].data;
    }

    get currentComponentTitle(): string {
        return this.flowOrdering[this.currentOrderIndex].title;
    }

    get currentOrderNumber(): number {
        return this.currentOrderIndex >= 0 && this.currentOrderIndex < this.flowOrdering.length
            ? this.flowOrdering[this.currentOrderIndex].orderNumber
            : -1;
    }

    getOrderIndexFromOrderNumber(orderNumber: number): number {
        return this.flowOrdering.findIndex(x => x.orderNumber == orderNumber);
    }

    goToPrevious() {
        this.currentOrderIndex--;
        if (this.flowOrdering[this.currentOrderIndex] && this.flowOrdering[this.currentOrderIndex].hide){
            this.goToPrevious();
        }
        this.isForwardMovement = false;
        this.openFlowComponent();
    }

    goToNext() {
        this.currentOrderIndex++;
        if (this.flowOrdering[this.currentOrderIndex] && this.flowOrdering[this.currentOrderIndex].hide){
            this.goToNext();
        }
        this.isForwardMovement = true;
        this.openFlowComponent();
    }

    // Данные компонента 'Результат работ'
    getCompleteActivityResultPropertiesData(): CompleteActivityResultPropertiesData {
        let data = new CompleteActivityResultPropertiesData;
        data.activity = this.activity;
        data.activityResults = this.activityResults;
        return data;
    }

    // Данные компонента 'Основное оборудование' для демонтажа
    getCompleteActivityDismantledSeriesData(requestDevice: NewRequestDevice = new NewRequestDevice): CompleteActivityDismantledSeriesData {
        let data = new CompleteActivityDismantledSeriesData(
            this.request.requestId, 
            this.activity.activityId,
            this.erpContragentInfo.needSecurityKeyForReserve, 
            this.request.customerContragentId, 
            requestDevice);

        if (requestDevice.requestDeviceId) {
            data.imagesData.requestDeviceImages = this.getFiles(AttachmentType.requestDeviceImage, requestDevice.requestDeviceId);
            data.imagesData.requestDeviceSerialNumberImages = this.getFiles(AttachmentType.uninstalledDevicesFotos, requestDevice.requestDeviceId);
        }
        return data;
    }

    // Данные компонента 'Сведения о SIM-карте' для демонтажа
    getCompleteActivityDismantledSimcardData(requestDevice: NewRequestDevice = new NewRequestDevice): CompleteActivityDismantledSimcardData {
        let data = new CompleteActivityDismantledSimcardData;
        data.mobileNomenclatures = this.mobileNomenclatures;
        data.requestId = this.request.requestId;
        data.activityId = this.activity.activityId;
        data.requestUninstallDevice = requestDevice;
        data.fillSubsriberNumber = this.request.customerContragentId == CustomerEnum.rtk;
        if (requestDevice.requestDeviceId) {
            data.attachments = this.getFiles(AttachmentType.uninstalledSimFoto, requestDevice.requestDeviceId);
        }
        return data;
    }

    // Данные компонента 'Основное оборудование' для монтажа
    getInstallFlowDeviceData(disableImagesStep: boolean = false): InstallFlowDeviceData {
        let data = new InstallFlowDeviceData(
            this.request.requestId, 
            this.activity.activityId,
            this.erpContragentInfo.needSecurityKeyForReserve, 
            this.request.customerContragentId);

        data.disableImagesStep = disableImagesStep;
        if (!disableImagesStep) {
            data.imagesData.requestDeviceImages = this.getFiles(AttachmentType.requestDeviceImage);
            data.imagesData.requestDeviceSerialNumberImages = this.getFiles(AttachmentType.installedDevicesFotos);
        }

        return data;
    }

    // Данные компонента 'Сведения о SIM-карте' для монтажа
    getInstallFlowSimcardData(): InstallFlowSimcardData {
        let data = new InstallFlowSimcardData;
        data.requestId = this.request.requestId;
        data.activityId = this.activity.activityId;
        data.attachments = this.getFiles(AttachmentType.installedSimFoto);    
        return data;
    }

    getInstallSignatureFlowData(): CompleteActivityInstallSignatureData {
        let data = new CompleteActivityInstallSignatureData;
        data.summary = this.getInstallFlowSummaryData();
        return data;
    }

    getUninstallSignatureFlowData(): CompleteActivityUninstallSignatureData {
        let data = new CompleteActivityUninstallSignatureData;
        data.summary = this.getCompleteActivitySummaryData();
        return data;
    }

    addDismantledSeriesFlowComponent(requestDevice: NewRequestDevice) {
        let flowOrderingItem = {
            orderNumber: ActivitySuccessResultFlowOrder.completeActivityDismantledSeries,
            data: this.getCompleteActivityDismantledSeriesData(requestDevice),
            title: "Основное оборудование"
        };
        let orderIndex = this.getOrderIndexFromOrderNumber(flowOrderingItem.orderNumber);
        this.flowOrdering.splice(orderIndex, 0, flowOrderingItem);
    }

    addSimcardFlowComponent(requestDevice: NewRequestDevice) {        
        let flowOrderingItem = {
            orderNumber: ActivitySuccessResultFlowOrder.completeActivityDismantledSimcard,
            data: this.getCompleteActivityDismantledSimcardData(requestDevice),
            title: "Сведения о SIM-карте"
        };
        let orderIndex = this.getOrderIndexFromOrderNumber(flowOrderingItem.orderNumber);
        this.flowOrdering.splice(orderIndex, 0, flowOrderingItem);
    }

    // Данные компонента 'Фото документов'
    getCompleteActivityRequiredAttachmentData(attachmentTypeId: number): CompleteActivityRequiredAttachmentData {
        let data = new CompleteActivityRequiredAttachmentData;
        data.requestId = this.request.requestId;
        data.activityId = this.activity.activityId;
        data.attachmentTypeId = attachmentTypeId;
        data.attachmentTypeName = this.attachmentTypes.find(x => x.id == attachmentTypeId).name;
        data.attachments = this.getFiles(attachmentTypeId, null);
        return data;
    }

    addRequiredAttachmentFlowComponent(attachmentTypeId: number) {
        let flowOrderingItem = {
            orderNumber: ActivitySuccessResultFlowOrder.completeActivityRequiredAttachment,
            data: this.getCompleteActivityRequiredAttachmentData(attachmentTypeId),
            title: "Фото документов"
        };
        let orderIndex = this.getOrderIndexFromOrderNumber(flowOrderingItem.orderNumber);
        this.flowOrdering.splice(orderIndex, 0, flowOrderingItem);
    }

    // Данные компонента 'Резюме' для демонтажа
    getCompleteActivitySummaryData() {
        let summary = new CompleteActivitySummaryData;

        summary.requestId = this.request.requestId;
        summary.activityId = this.activity.activityId;
        let activityResultData = this.flowOrdering.find(x =>
            x.orderNumber == ActivitySuccessResultFlowOrder.completeActivityResultProperties).data as CompleteActivityResultPropertiesData;
        summary.activityResultId = activityResultData.activityResultId;
        summary.activityResultName =  this.activityResults.find(x => x.id == summary.activityResultId).name;
        summary.completedDateTime = activityResultData.completedDateTime;

        summary.generalEquipments = this.flowOrdering
            .filter(x => x.orderNumber == ActivitySuccessResultFlowOrder.completeActivityDismantledSeries)
            .map(x => {
                let seriesData = x.data as CompleteActivityDismantledSeriesData;
                if (seriesData.requestUninstallDeviceId != null) {
                    let generalEquipmentData = new CompleteActivitySummaryGeneralEquipment;
                    generalEquipmentData.nomenclature = seriesData.serialAndPartNumbersData.nomenclature;
                    generalEquipmentData.serialNumber = seriesData.serialAndPartNumbersData.serialNumber;
                    generalEquipmentData.partNumber = seriesData.serialAndPartNumbersData.partNumber;
                    generalEquipmentData.deviceConditionName = seriesData.generalPropertiesData.deviceConditionName;
                    return generalEquipmentData;
                }
                return null;
            });
       
        summary.simcardEquipments = this.flowOrdering
            .filter(x => x.orderNumber == ActivitySuccessResultFlowOrder.completeActivityDismantledSimcard)
            .map(x => {
                let simcardData = x.data as CompleteActivityDismantledSimcardData;
                if (simcardData.requestUninstallDevice.requestDeviceId != null) {
                    let simcardEquipmentData = new CompleteActivitySummarySimcardEquipment;
                    simcardEquipmentData.deviceModelName = simcardData.requestUninstallDevice.nomenclature;
                    simcardEquipmentData.iccid = simcardData.requestUninstallDevice.iccid;
                    simcardEquipmentData.subscriberNumber = simcardData.requestUninstallDevice.subscriberNumber;
                    return simcardEquipmentData;
                }
                return null;
            });

        summary.generalEquipments = summary.generalEquipments.filter(x => x != null);
        summary.simcardEquipments = summary.simcardEquipments.filter(x => x != null);

        return summary;
    }

    // Данные компонента 'Резюме' для монтажа
    getInstallFlowSummaryData() {
        let summary = new InstallFlowSummarySummaryData;

        summary.requestId = this.request.requestId;
        summary.activityId = this.activity.activityId;
        let activityResultData = this.flowOrdering.find(x =>
            x.orderNumber == ActivitySuccessResultFlowOrder.completeActivityResultProperties).data as CompleteActivityResultPropertiesData;
        summary.activityResultId = activityResultData.activityResultId;
        summary.activityResultName =  this.activityResults.find(x => x.id == summary.activityResultId).name;
        summary.completedDateTime = activityResultData.completedDateTime;
        summary.comment = activityResultData.activity.finishActivityComment;
        summary.activityWorkflowTransitionId = this.activityWorkflowTransitionId;        

        let generalEquipments: SummaryDeviceEquipment[] = [];
        this.flowOrdering
            .filter(x => x.orderNumber == ActivitySuccessResultFlowOrder.completeActivityDismantledSeries)
            .forEach(x => {
                let deviceData = x.data as InstallFlowDeviceData;
                if (deviceData.serialsData && deviceData.serialsData.length > 0) {
                    deviceData.serialsData.forEach(serialData => {
                        if (serialData.serialNumber) {
                            let generalEquipment = { 
                                serialNumber: serialData.serialNumber,
                                nomenclature: serialData.nomenclature
                            } as SummaryDeviceEquipment;
                            generalEquipments.push(generalEquipment);
                        }
                        if (serialData.serialItems != null && serialData.serialItems.length > 0) {
                            serialData.serialItems.forEach(serialItem => {
                                if (serialItem.serialNumber != null) {
                                    let generalEquipment = { 
                                        serialNumber: serialItem.serialNumber,
                                        nomenclature: serialItem.nomenclature
                                    } as SummaryDeviceEquipment;
                                    generalEquipments.push(generalEquipment);
                                }
                            });
                        }
                    });
                }
                if (deviceData.serialAndPartNumbersData != null && deviceData.serialAndPartNumbersData.serialNumber != null) {
                    generalEquipments.push({
                        serialNumber: deviceData.serialAndPartNumbersData.serialNumber,
                        nomenclature: deviceData.serialAndPartNumbersData.nomenclature
                    } as SummaryDeviceEquipment);
                }
            });

        summary.generalEquipments = generalEquipments.filter(x => x != null);
        summary.simcardEquipments = this.getInstallSimcardEquipmentData().filter(x => x != null);

        let devicesToInstall = summary.generalEquipments.map(x => {
            return {
                serialNumber: x.serialNumber,
                nomenclature: x.nomenclature
            } as DeviceInfo;
        });
        summary.simcardEquipments.forEach(x => {
            devicesToInstall.push({
                serialNumber: x.iccid,
                nomenclature: x.nomenclature
            } as DeviceInfo);
        });
        summary.devicesToInstall = devicesToInstall;

        return summary;
    }

    getInstallSimcardEquipmentData(): SummarySimcardEquipment[] {
        let allSimcardEquipments: SummarySimcardEquipment[] = [];
        this.flowOrdering
            .filter(x => x.orderNumber == ActivitySuccessResultFlowOrder.completeActivityDismantledSimcard)
            .forEach(x => {
                let simcardData = x.data as InstallFlowSimcardData;
                simcardData.requestDevices.forEach(requestDevice => {
                    if (requestDevice.iccid) {
                        let simcardEquipmentData = new SummarySimcardEquipment;
                        simcardEquipmentData.iccid = requestDevice.iccid;
                        simcardEquipmentData.nomenclature = requestDevice.nomenclature;
                        allSimcardEquipments.push(simcardEquipmentData);
                    }
                });
            });

        return allSimcardEquipments;
    }

    get getUninstallSimcardEquipmentData(): string[] {
        let allEquipments: string[] = [];
        this.flowOrdering
            .filter(x => x.orderNumber == ActivitySuccessResultFlowOrder.completeActivityDismantledSeries)
            .forEach(x => {
                let serialData = x.data as InstallFlowDeviceData;
                allEquipments =[...allEquipments, 
                    serialData.serialAndPartNumbersData.nomenclature,
                    serialData.serialAndPartNumbersData.serialNumber,
                    ' '];
            });

        return allEquipments;
    }

    // вспомогательные методы
    private removeLastDefaultItem(orderNumber: number) {
        for (let i = this.flowOrdering.length - 1; i >= 0; i--) {
            if (this.flowOrdering[i].orderNumber === orderNumber) {
                this.flowOrdering.splice(i, 1);
                break;
            }
        }  
    }

    private initFlowOrderingData(flowOrderNumber: number, data: any) {
        this.flowOrdering
            .filter(x => x.orderNumber == flowOrderNumber)
            .forEach(x => x.data = data);
    }

    private getFiles(attachmentTypeId: number, requestDeviceId: number = null) {
        if (requestDeviceId){
            return this.activity.attachments
            .filter(attachment => 
                attachment.attachmentTypeIds.includes(attachmentTypeId) &&
                attachment.requestDeviceId == requestDeviceId &&
                this.uploadedFiles.find(x => x.uid == attachment.attachmentId.toString()) != null)
            .map(attachment => {
                return this.uploadedFiles.find(x => x.uid == attachment.attachmentId.toString());
            });
        } else {
            return this.activity.attachments
            .filter(attachment => 
                attachment.attachmentTypeIds.includes(attachmentTypeId) &&
                this.uploadedFiles.find(x => x.uid == attachment.attachmentId.toString()) != null)
            .map(attachment => {
                return this.uploadedFiles.find(x => x.uid == attachment.attachmentId.toString());
            });
        }
    }

    async loadFileData(attachment: Attachment) { 
        let file = new UploadFileData;
        file.uid = attachment.attachmentId.toString();
        file.fileName = attachment.attachmentName;
        file.fileSize = attachment.attachmentSize;
        file.fileExtension = attachment.attachmentExtension;
        file.isUploadedOnServer = true;

		let blob = await this.attachmentsService.getAttachmentById(attachment.attachmentId).toPromise();
        var reader = new FileReader();
		reader.readAsDataURL(blob); 
		reader.onloadend = function() {
			file.fileData = (reader.result as string).split(',')[1];
		}

        return file;
    }

    hideComponent(order: number, hide: boolean = false) {
        var components = this.flowOrdering.filter(x => x.orderNumber == order);
        console.log(components.length);

        if (components.length == 0) 
            return;

        components.forEach(x => x.hide = hide);
    }
}
