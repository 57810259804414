import { Observable, of } from "rxjs";
import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild } from "@angular/core";
import SignaturePad from "signature_pad";

@Component({
    selector: 'complete-activity-signature',
    templateUrl: './complete-activity-signature.component.html',
    styleUrls: ['./complete-activity-signature.component.scss',
        '../../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
        '../../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss']
})
export class CompleteActivitySignatureComponent implements OnInit
{
    @ViewChild("canvas") canvas: ElementRef;
    sig: SignaturePad;
  
    @Output() uploadSignature: EventEmitter<string> = new EventEmitter<string>();

    ngOnInit() {
        this.sig = new SignaturePad(this.canvas.nativeElement);
        this.sig.addEventListener("endStroke", () => {
            var img = this.sig.toDataURL("image/jpg");
            this.uploadSignature.emit(img.split(',')[1]);
        }, { once: false });
    }

    clear() {
      this.sig.clear();
      this.uploadSignature.emit(null);
    }

    get canvasWidth(): number {
        return window.innerWidth - 30;
    }

    get canvasHeight(): number {
        let rate = 0;
        if (window.innerHeight > 900) rate = 3.3;
        else if (window.innerHeight > 800) rate = 3.5;
        else if (window.innerHeight > 760) rate = 3.7;
        else if (window.innerHeight > 700) rate = 3.9;
        else if (window.innerHeight > 650) rate = 4.5;
        else rate = 5;

        return window.innerHeight / rate;
    }
}