import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DialogService } from "@progress/kendo-angular-dialog";
import { AppService } from "../../../app.service";
import { NotificationService } from "../../../core/services/notification.service";
import { ComponentPageBase } from "../../../shared/components/component-page-base/component-page-base";
import { CategoryType } from "../../../shared/enums/category-type.enum";
import { EntityViewModel } from "../../../shared/models/core/EntityViewModel";
import { KeyValueObject } from "../../../shared/models/core/KeyValueObject";
import { ServiceCategoryItem } from "../../../shared/models/services/service-category-item";
import { LookupService } from "../../../shared/services/lookup.service";
import { ServiceCategoriesService } from "../../../shared/services/service-categories.service";
import { ServiceModeForm } from "../../service-modes/service-mode/service-mode-form/service-mode.form";

@Component({
	selector: 'service-category-page',
	templateUrl: './service-category.page.html',
	styleUrls: [
		'./service-category.page.scss',
		'../../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	]
})
export class ServiceCategoryPage extends ComponentPageBase<ServiceCategoryItem> implements OnInit {

	activeIdString: any = "main";
	categoryTypeId = <number>CategoryType.serviceCategories;

	serviceModes: KeyValueObject[] = [];

	constructor(
		protected route: ActivatedRoute,
		protected router: Router,
		protected appService: AppService,
		protected notificationService: NotificationService,
		protected dataService: ServiceCategoriesService,
		private lookupService: LookupService,
		private dialogService: DialogService,
	) {
		super(route, router, appService, notificationService, dataService);
		this.listPageRoutePath = 'categories/service-categories';
		this.appService.pageTitle = 'Новая категория обслуживания';
	}

	ngOnInit(): void {
		this.entityId = this.route.snapshot.paramMap.get('serviceCategoryId');

		this.lookupService.getData("service-modes", null).subscribe(data => this.serviceModes = data);

		if (this.entityId != null) {

			this.dataService.getById(+this.entityId)
				.subscribe(vm => {
					this.entityName = `${vm.entity.name}`;
					this.dataBind(vm);
				});

		} else {

			this.entityViewModel = new EntityViewModel<ServiceCategoryItem>();
			this.entityViewModel.entity = new ServiceCategoryItem();
			this.entityViewModel.entity.createdDate = new Date();
			this.canEditForm = true;
			this.isNew = true;

		}
	}

	onAddServiceMode(): void {
		this.showAddServiceModeModalWindow();
	}

	private showAddServiceModeModalWindow(): void {
		const dialogRef = this.dialogService.open({ content: ServiceModeForm, width: '100%' });

		const serviceModeForm = <ServiceModeForm>dialogRef.content.instance;
		serviceModeForm.showCancelButton = true;
		serviceModeForm.header = 'Новый режим обслуживания';

		serviceModeForm.afterSaveChangesEvent.subscribe(serviceMode => this.onSavedServiceMode(serviceMode));
	}

	private onSavedServiceMode(serviceMode: KeyValueObject): void {
		this.serviceModes.push(serviceMode);
	}

	validateSlaMinutesInput(event) {
		let inputElement = event.target as HTMLInputElement;
		let value = inputElement.value.replace(/[^0-9]/g, ''); // Удаляем нечисловые символы

		if (value !== '' && Number(value) > 59) {
			value = '59'; // Ограничиваем до 59
		}

		inputElement.value = value; // Обновляем значение поля
		this.entityViewModel.entity.slaMinutes = value;
	}

	validateSlaHoursInput(event) {
		let inputElement = event.target as HTMLInputElement;
		let value = inputElement.value.replace(/[^0-9]/g, ''); // Удаляем нечисловые символы

		inputElement.value = value; // Обновляем значение поля		
	}
}
