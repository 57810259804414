import { Component } from "@angular/core";
import { RequestsService } from "../../../../services/requests.service";
import { BaseFlowComponent } from "../base-flow-component/base-flow-component";
import { CompleteActivityUninstallSignatureData } from "./complete-activity-uninstall-signature.data";
import { Observable, of } from "rxjs";
import { UploadFile } from "../../../../models/attachment/upload-file";
import { Guid } from "guid-typescript";
import { CommentKindEnum } from "../../../../enums/comment-kind.enum";
import { CommentTypeEnum } from "../../../../enums/comment-type.enum";
import { NewCommentModel } from "../../../../models/request/comments/new-comment-model";
import { ActivityFinishSuccesfully } from "../../../../models/activity/activity-finish-succesfully";
import { NotificationType } from "../../../../../core/services/notification-type";
import { ActivitiesService } from "../../../../services/activities.service";
import { NotificationService } from "../../../../../core/services/notification.service";
import { AttachmentType } from "../../../../enums";

@Component({
    selector: 'complete-activity-uninstall-signature',
    templateUrl: './complete-activity-uninstall-signature.component.html',
    styleUrls: ['./complete-activity-uninstall-signature.component.scss',
        '../../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
        '../../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss']
})
export class CompleteActivityUninstallSignatureComponent 
    extends BaseFlowComponent<CompleteActivityUninstallSignatureData>  {
    
    merchantEmailDisabled: boolean;
    private signatureData: string;

    constructor(
        public requestsService: RequestsService,
        public activitiesService: ActivitiesService,
        public notificationService: NotificationService
    ) {
        super();
    }

    initData() {
    }

    saveChanges(): Observable<any> {
        return of({});
    }

    uploadSignature(data: string) {
        this.signatureData = data;
    }

    finishSuccesfully() {
        this.dataSaving = true;
        let model = new ActivityFinishSuccesfully;
        model.requestId = this.data.summary.requestId;
        model.activityId = this.data.summary.activityId;
        model.newFinishDateFact = this.data.summary.completedDateTime;
        model.activityResultId = this.data.summary.activityResultId;

        model.merchantMemberName = this.data.merchantMember;
        model.merchantMemberEmail = !this.merchantEmailDisabled
            ? this.data.merchantEmail
            : null;

        this.activitiesService.finishSuccesfully(model).subscribe((result) => {
            if (!result.data.success) {
				this.notificationService.error({
					title: 'Ошибка при успешном завершении активности',
					message: result.data.errorMessage,
					notificationType: NotificationType.Toast
				});
                this.dataSaving = false;
            }
            else {
                this.sendComment();
            }
        });
    }

    private sendComment() {
        let file = new UploadFile;
		file.fileName = `confirm-signature_${this.data.summary.requestId}_${this.data.summary.activityId}.jpg`;
		file.fileExtension = '.jpg';
		file.uid = Guid.create().toString();
		file.attachmentTypeIds = [AttachmentType.merchantMemberSignature]
		file.attachmentTypeId = AttachmentType.merchantMemberSignature;
		file.fileData = this.signatureData;
		
        const commentText = !this.merchantEmailDisabled
            ? `Работу принял представитель ТСП ${this.data.merchantMember} (email: <a class="text-white" href="mailto:${this.data.merchantEmail}" target="_blank">${this.data.merchantEmail}</a>)`
            : `Работу принял представитель ТСП ${this.data.merchantMember}`;
    
		this.requestsService.addComment({
			requestId: this.data.summary.requestId,
			activityId: this.data.summary.activityId,
			text: commentText,
			uploadAttachments: [file],
			commentType: CommentTypeEnum.user,
			commentKind: CommentKindEnum.internal
		} as NewCommentModel).subscribe(() => {
            this.dataSaving = false
			this.goToNext();
		});
    }

    get canSave(): boolean {
        let merchantMemberValid = this.data.merchantMember && this.data.merchantMember.trim() != '';
        let merchantEmailValid = this.merchantEmailDisabled || this.merchantEmailValid();
        return merchantMemberValid && merchantEmailValid && this.signatureData != null;
    }

    changeMerchantEmailDisabled(event: any) {
        if (event.target.checked) {
            this.data.merchantEmail = '';
        }
    }

    merchantEmailValid() {
        const emailRegex = /^[a-zA-Z][a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/;
        let email = this.data.merchantEmail;
        return email != null && email.trim() != '' && emailRegex.test(email);
    }
}