import { BaseObject } from "../core/BaseObject";

export class ServiceCategoryItem extends BaseObject {
	id: number;
	name: string;
	sla: number;
	slaHoursMinutes: string;
	slaHours: string;
	slaMinutes: string;
	serviceModeId: number;
	minDistance: number;
	maxDistance: number;
	/**
	* Коэффициент стоимости SLA
	**/
	standartHourPrice: number;
}
