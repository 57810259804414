import { Observable, forkJoin, of } from "rxjs";
import { BaseFlowComponent } from "../../base-flow-component/base-flow-component";
import { InstallFlowDeviceData } from "./install-flow-device.data";
import { Component, Input } from "@angular/core";
import { InstallFlowDeviceOrder } from "./install-flow-device-order";
import { RequestUninstallDevicesService } from "../../../../../services/request-uninstall-devices.service";
import { AttachmentsService } from "../../../../../services/attachments.service";
import { DeviceInfo } from "../../../../../models/device/device-info";
import { Attachment, UploadAttachmentsModel } from "../../../../../models/attachment";
import { AttachmentType } from "../../../../../enums";
import { tap } from "rxjs/operators";
import { UploadFileData } from "../../../../../models/attachment/upload-file-data";
import { ActivitiesService } from "../../../../../services/activities.service";
import { DeviceAttachmentsService } from "../../../../../services/device-attachments.service";
import { AnalyzeInstallDeviceAttachmentRequest } from "../../../../../models/device-attachments/analyze-install-device-attachment-request";
import { InstallFlowDeviceSerialData, InstallFlowDeviceSerialDataItem } from "./install-flow-device-serial/install-flow-device-serial-data";
import { ConfirmAnalyzedSerialImagesData, ConfirmAnalyzedSerialImagesDataItem } from "../../confirm-analyzed-serial-images/confirm-analyzed-images.data";

@Component({
    selector: 'install-flow-device',
    templateUrl: './install-flow-device.component.html',
    styleUrls: ['./install-flow-device.component.scss',
        '../../../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
        '../../../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss']
})
export class InstallFlowDeviceComponent 
    extends BaseFlowComponent<InstallFlowDeviceData>
{  
    StepOrder = InstallFlowDeviceOrder;
    skipToNextStep = false;
    uploading = false;
    currentSerialDataIndex: number = 0;
    currentStepIndex: number = 0;
    uploadedAttachmentIds: number[] = [];

    stepOrdering = [
        InstallFlowDeviceOrder.dismantledImages,
        InstallFlowDeviceOrder.confirmAnalyzedData,
        InstallFlowDeviceOrder.serial,
        InstallFlowDeviceOrder.addedMoreSerial
    ];

    @Input() uploadOnClick: boolean = false;

    constructor(
        public requestUninstallDevicesService: RequestUninstallDevicesService,
        public attachmentsService: AttachmentsService,
        private activitiesService: ActivitiesService,
        private deviceAttachmentsService: DeviceAttachmentsService,)
    {
        super();
    }

    initData() {
        if (this.data.disableImagesStep){
            this.stepOrdering = [
                InstallFlowDeviceOrder.serial
            ];
            this.data.serialsData = [{ 
            } as InstallFlowDeviceSerialData];
        }

        this.currentStepIndex = this.isForwardMovement ? 0 : this.stepOrdering.length - 1;
        this.currentSerialDataIndex = this.isForwardMovement ? 0 : this.data.serialsData.length - 1;

        if (!this.isForwardMovement && this.data.serialsData.length == 0) {
            this.currentStepIndex = 0;
        }
    }

    saveChanges(): Observable<any> {
        return of(null);
    }

    goToNextStep(): void {
        this.isForwardMovement = true;

        if (this.useImageAnalyze) {
            switch (this.currentStepNumber) {
                case InstallFlowDeviceOrder.dismantledImages:
                    this.uploadAttachments();
                    break;

                 case InstallFlowDeviceOrder.confirmAnalyzedData:
                    this.setStepNumber(InstallFlowDeviceOrder.serial)
                    break;

                case InstallFlowDeviceOrder.serial:
                    this.checkSerialStep();
                    this.setStepNumber(InstallFlowDeviceOrder.addedMoreSerial);
                    break;
                
                case InstallFlowDeviceOrder.addedMoreSerial:
                    this.goToNext();
                    break;
            }
        }
        else {
            if (this.currentStepNumber == InstallFlowDeviceOrder.dismantledImages) {
                if (this.data.serialsData.length > 0) {
                    this.currentStepIndex++;
                }
                else {
                    this.currentStepIndex = this.stepOrdering.length - 1;
                }
            }
            else if (this.currentStepNumber == InstallFlowDeviceOrder.serial) {
                if (this.currentSerialDataIndex == this.data.serialsData.length - 1) {
                    this.currentStepIndex++;
                }
                else {
                    this.currentSerialDataIndex++;
                }
            }
            else {
                this.goToNext();
            }
        }
    }

    goToPreviousStep(): void {
        if (!this.useImageAnalyze) {
            if (this.currentStepNumber == InstallFlowDeviceOrder.serial &&
                this.currentSerialDataIndex != 0) {
                this.currentSerialDataIndex--;
            }
            else {
                this.currentStepIndex--;
            }

            if (this.currentStepIndex == -1) {
                this.goToPrevious();
            }
        }
        else {
            this.isForwardMovement = false;
            this.currentStepIndex--;
            if (this.currentStepIndex < 0) {
                this.goToPrevious();
            }
    
            if (this.stepOrdering[this.currentStepIndex] == InstallFlowDeviceOrder.serial) {
                this.checkSerialStep();
                return;
            }
        }
    }

    goToNextAfterConfirmation() {
        if (this.data.serialsData.length == 0 || this.data.serialsData.every(x => !x.valid)) {
            this.confirmCreateSerial();
        }
        else {
            this.setStepNumber(InstallFlowDeviceOrder.addedMoreSerial);
        }
    }

    manualCorrect() {
        this.goToNextStep();
    }

    backFromAnalyzing() {
        this.data.serialsData = [];
        this.goToPreviousStep();
    }

    cancelAnalyzing() {
        this.data.imagesData.requestDeviceSerialNumberImages = [];
        this.data.serialsData = [];
        this.setStepNumber(InstallFlowDeviceOrder.dismantledImages); 
    }

    private uploadAttachments() {
        if (!this.useImageAnalyze) {
            this.uploadAttachmentsRequest().subscribe(() => {
                this.currentStepIndex++;
                this.confirmCreateSerial();
            });
            return;
        }

        if (this.data.imagesData.requestDeviceSerialNumberImages.length > 0 &&
            !this.data.imagesData.requestDeviceSerialNumberImages.some(x => !x.aiAnalyzed)) {
            this.setStepNumber(InstallFlowDeviceOrder.confirmAnalyzedData);
            return;
        }
        else if (this.data.imagesData.requestDeviceSerialNumberImages.length == 0) {
            this.setStepNumber(InstallFlowDeviceOrder.addedMoreSerial);
        }

        this.uploading = true;
        this.uploadAttachmentsRequest().subscribe(() => {
            let analyzeRequest = {
                requestId: this.data.requestId,
                activityId: this.data.activityId
            } as AnalyzeInstallDeviceAttachmentRequest;
            this.deviceAttachmentsService.analyzeInstallAttachments(analyzeRequest).subscribe((resp) => {
                this.data.imagesData.requestDeviceSerialNumberImages.forEach(x => x.aiAnalyzed = true);
                resp.data.forEach(x => {

                    let serialData = { 
                        aiRecognizeError: !x.isSuccessful,
                        valid: x.isSuccessful,
                        requestDeviceAttachmentCorrect: x.requestDeviceAttachmentCorrect,
                        isMultiple: x.resultItems.length > 1
                    } as InstallFlowDeviceSerialData;
                    if (serialData.isMultiple) {
                        serialData.serialItems = x.resultItems.map(resultItem => {
                            return {
                                serialNumber: resultItem.serialNumber,
                                nomenclature: resultItem.nomenclature,
                                isSuccessful: resultItem.isSuccessful
                            } as InstallFlowDeviceSerialDataItem;
                        });
                    }
                    else if (x.resultItems.length == 0) {
                        serialData.valid = false;
                        serialData.aiRecognizeError = true;
                    }
                    else {
                        serialData.serialNumber = x.resultItems[0].serialNumber;
                        serialData.nomenclature = x.resultItems[0].nomenclature;
                    }

                    this.data.serialsData.push(serialData);
                });

                this.uploading = false;
                this.setStepNumber(InstallFlowDeviceOrder.confirmAnalyzedData);
                return;
            });
        });
    }

    checkSerialStep() {
        if (this.isForwardMovement) {
            while (this.currentSerialDataIndex < this.data.serialsData.length) {
                if (this.currentSerialData.valid) this.currentSerialDataIndex++;
                else break;
            }

            if (this.currentSerialDataIndex == this.data.serialsData.length) {
                let multipleSerials = this.data.serialsData.filter(x => x.isMultiple);
                multipleSerials.forEach(multipleSerial => {
                    multipleSerial.serialItems.forEach(item => {
                        this.data.serialsData.push({
                            serialNumber: item.serialNumber,
                            nomenclature: item.serialNumber,
                            valid: true
                        } as InstallFlowDeviceSerialData);
                    });
                });
                this.data.serialsData = this.data.serialsData.filter(x => !x.isMultiple);
                this.currentSerialDataIndex = this.data.serialsData.length - 1;
                this.currentStepIndex++;
            }
        }
        else {
            while (this.currentSerialDataIndex >= 0) {
                if (this.currentSerialData.valid) this.currentSerialDataIndex--;
                else break;
            }
            if (this.currentSerialDataIndex <= 0) {
                this.currentSerialDataIndex = 0;
                this.currentStepIndex--;
            }
        }
    }

    confirmCreateSerial() {
        this.data.serialsData = [
            ...this.data.serialsData, 
            { 
                serialNumber: null,
                nomenclature: null,
                valid: false,
                aiRecognizeError: false,
                addedManually: true
            } as InstallFlowDeviceSerialData
        ];
        this.currentStepIndex = this.stepOrdering.findIndex(x => x == this.StepOrder.serial);
        this.currentSerialDataIndex = this.data.serialsData.length - 1;
    }

    skipCreateSerial() {
        if (this.isForwardMovement) {
            this.goToNextStep();
        }
        else {
            this.goToPreviousStep();
        }
    }

    setStepNumber(stepNumber) {
        let stepIndex = this.stepOrdering.findIndex(x => x == stepNumber);
        this.currentStepIndex = stepIndex;
    }

    get currentStepNumber(): number {
        return this.stepOrdering[this.currentStepIndex];
    }

    get currentSerialData(): InstallFlowDeviceSerialData {
        if (this.currentStepNumber == this.StepOrder.serial) {
            return this.data.serialsData[this.currentSerialDataIndex];
        }

        return null;
    }

    uploadAndReload() {
        this.uploadAttachmentsRequest().subscribe(() => this.reloadAllAttachments());
    }
    
    private uploadAttachmentsRequest(): Observable<any> {
        this.data.imagesData.requestDeviceImages.forEach(x => {
            x.attachmentTypeId = AttachmentType.requestDeviceImage;
            x.isTemporary = true;
        });
        this.data.imagesData.requestDeviceSerialNumberImages.forEach(x => {
            x.attachmentTypeId = AttachmentType.installedDevicesFotos;
            x.isTemporary = true;
        });

        let attachments = this.data.imagesData.requestDeviceImages.concat(this.data.imagesData.requestDeviceSerialNumberImages);
        let attachmentsToUpload = attachments.filter(x => !x.isUploadedOnServer && !x.isRemoved && !x.aiAnalyzed);
        let attachmentsToRemove = attachments.filter(x => x.isUploadedOnServer && x.isRemoved);

        const uploadModel = new UploadAttachmentsModel({ 
            requestId: this.data.requestId, 
            activityId: this.data.activityId,
            attachments: attachmentsToUpload
        });

        let attachmentObservables$: Observable<any>[] = [];

        if (attachmentsToRemove.length > 0) {
            attachmentObservables$ = attachmentsToRemove.map((x) => this.attachmentsService.remove(x.uid, null, null, true));
        }

        if (attachmentsToUpload.length > 0) {
            attachmentObservables$.push(
                this.attachmentsService.uploadWithResumableIfMobile(uploadModel)
                .pipe(tap((resp) => {
                    if (resp && resp.body && resp.body.data) {
                        this.uploadedAttachmentIds = resp.body.data;
                    }
                    attachmentsToUpload.forEach(x => { x.isUploadedOnServer = true; });
                })));
        }

        return attachmentObservables$.length > 0 
            ? forkJoin(attachmentObservables$) 
            : of({}); 
    }

    removeFile(file: UploadFileData){
        if (file) {
            this.attachmentsService.remove(file.uid, null, null, true).subscribe(() => {
                this.reloadAllAttachments();
            });
        }
    }

    reloadAllAttachments(){
        this.activitiesService.getById(this.data.activityId).subscribe(activity => {
            this.loadAttachmentsForType(AttachmentType.requestDeviceImage, activity.entity.attachments).subscribe(x => {
                this.data.imagesData.requestDeviceImages = x;
                this.data.imagesData.requestDeviceImages.forEach(x => x.isUploadedOnServer = true);
            });
            this.loadAttachmentsForType(AttachmentType.installedDevicesFotos, activity.entity.attachments).subscribe(x => {
                this.data.imagesData.requestDeviceSerialNumberImages = x;
                this.data.imagesData.requestDeviceSerialNumberImages.forEach(x => x.isUploadedOnServer = true);
            });
        });
    }

    loadAttachmentsForType(type: AttachmentType, attachments: Attachment[]): Observable<UploadFileData[]>{
        return new Observable((observer) => {
            var files = attachments
            .filter(x => x.attachmentTypeIds.includes(type))
            .map(attachment => {
                return this.loadFileData(attachment);
            });
            Promise.all(files)
                .then((results) => {
                    observer.next(results);
                    observer.complete();
                });
          });
    }

    async loadFileData(attachment: Attachment) { 
        let file = new UploadFileData;
        file.uid = attachment.attachmentId.toString();
        file.fileName = attachment.attachmentName;
        file.fileSize = attachment.attachmentSize;
        file.fileExtension = attachment.attachmentExtension;
        file.isUploadedOnServer = true;

		let blob = await this.attachmentsService.getAttachmentById(attachment.attachmentId).toPromise();
        var reader = new FileReader();
		reader.readAsDataURL(blob); 
		reader.onloadend = function() {
			file.fileData = (reader.result as string).split(',')[1];
		}

        return file;
    }

    public imagesUploadedEvent(skipToNextStep: boolean){
        this.skipToNextStep = skipToNextStep;
    }

    get confirmationAnalyzedData(): ConfirmAnalyzedSerialImagesData {
        let dataItems = [] as ConfirmAnalyzedSerialImagesDataItem[];

        this.data.serialsData.forEach(serialData => {
            if (serialData.isMultiple) {
                serialData.serialItems.forEach(serialItem => {
                    if (serialItem.isSuccessful) {
                        dataItems.push({
                            serialNumber: serialItem.serialNumber,
                            nomenclature: serialItem.nomenclature,
                        } as ConfirmAnalyzedSerialImagesDataItem);
                    }
                });
            }
            else if (serialData.valid) {
                dataItems.push({
                    serialNumber: serialData.serialNumber,
                    nomenclature: serialData.nomenclature
                } as ConfirmAnalyzedSerialImagesDataItem);            
            }
        });

        return {
            withError: dataItems.length == 0,
            requestDeviceAttachmentCorrect: this.data.serialsData.every(x => x.requestDeviceAttachmentCorrect),
            serialNumberRecognized: this.data.serialsData.every(x => x.valid),
            partNumberRecognized: this.data.serialsData.every(x => x.valid),
            serialNumberAvailable: this.data.serialsData.every(x => x.valid),
            partNumberAvailable: this.data.serialsData.every(x => x.valid),
            items: dataItems
        } as ConfirmAnalyzedSerialImagesData;
    }
}